<template>
<div id="applet">
   <div class="app-top">
      <img src="@/assets/imgs/logo.png" />
      <span>{{info.corporateName}}</span>
   </div>
   <div class="appct">
      <p>请进入小程序浏览页面</p>
      <span></span>
      <label></label>
      <img :src="info.smallRoutinePic" />
   </div>
</div>
</template>

<script>
import { getCompanyInfo} from "@/api/index.js";
export default {
  name: "applet",
  data() {
    return {
     info:{}
    }
  },
  mounted(){
    getCompanyInfo().then(res=>{
      this.info = res.data
    })
  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>
#applet{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background: linear-gradient(0deg, #F3F3F3 1%, #D6E9FF 100%);
}
.app-top{
  text-align: center;
  img{
     width: 100px;
     margin: 90px auto 10px;
  }
  span{
    display: block;
    color: #222;
    font-size: 20px;
  }
}
.appct{
  width: 88%;
  min-height: 300px;
  margin-left: 6%;
  background: #fff;
  border-radius: 10px;
  margin-top: 30px;
  position: relative;
  text-align: center;
  p{
    height: 45px;
    line-height: 45px;
    color: #222;
    text-align: center;
    border-bottom: 1px solid #eee;
  }
  span{
    display: block;
    position:absolute;
    width: 10px;
    height: 20px;
    right: 0;
    top: 36px;
    border-radius: 10px 0 0 10px;
    background:#D6E9FF ;
  }
  label{
      display: block;
    position:absolute;
    width: 10px;
    height: 20px;
    left: 0;
    top: 36px;
    border-radius: 0 10px 10px 0;
    background:#D6E9FF ;
  }
  img{
    width: 160px;
    margin-top: 35px;
  }
}
</style>
